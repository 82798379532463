import { FC } from "react"
import React from "reactn"
import { graphql } from "gatsby"
import marked from "marked"
import Meta, { MetaProps } from "../components/Meta"
import PageHeader from "../components/PageHeader"
import VimeoPlayer from "../components/VimeoPlayer"
import { ImgProps } from "../components/Img"

interface NxtPageTemplateProps {
  title: string,
  subtitles: string[],
  vimeoId: string,
  img: ImgProps,
  html: string
}

const NxtPageTemplate: FC<NxtPageTemplateProps> = ({
  title,
  subtitles,
  vimeoId,
  img,
  html
}) => {
  return (
    <main id="index-page">
      <section
        id="video"
        className="relative bg-cover bg-bottom"
      >
        <VimeoPlayer
          vimeoId={vimeoId}
          autoplay={true}
          muted={true}
          playsinline={true}
        />
      </section>

      <section
        id="informasjon"
        className="bg-cover bg-center"
        style={{ backgroundImage: `url(${img.src})` }}
      >
        <div
          className="text-white text-center h-full w-full px-6 pt-16 pb-32"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.33)" }}
        >
          <h1
            className="title font-thin mb-12"
            dangerouslySetInnerHTML={{
              __html: marked(title)
            }}
          />

          <h2 className="title text-xl uppercase tracking-widest">
            {subtitles.map((subtitle, index) => (
              <span
                className="mx-2"
                key={index}
              >
                {subtitle}
              </span>
            ))}
          </h2>

          <hr className="sep my-16 bg-white" />

          <div
            className="text-left max-w-3xl cc-2 mx-auto markdown"
            dangerouslySetInnerHTML={{
              __html: marked(html)
            }}
          />
        </div>
      </section>
    </main>
  )
}

interface NxtPageProps {
  data: {
    markdownRemark: {
      frontmatter: NxtPageTemplateProps & {
        meta: MetaProps
      },
      html: string
    }
  }
}

const NxtPage: React.FC<NxtPageProps> = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark
  return (
    <>
      <Meta {...frontmatter.meta} />

      <PageHeader />

      <NxtPageTemplate
        title={frontmatter.title}
        subtitles={frontmatter.subtitles}
        vimeoId={frontmatter.vimeoId}
        img={frontmatter.img}
        html={html}
      />
    </>
  )
}

export default NxtPage

export const NxtPageQuery = graphql`
  query NxtPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        meta {
          title
          description
          css
          js
        }
        title
        subtitles
        vimeoId
        img {
          src
          alt
        }
      }
      html
    }
  }
`
