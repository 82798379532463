import React, { FC } from "react"
import styled from "styled-components"
import { stringifyParams } from "../utils/url"
import cn from "../utils/cn"

const Container = styled.div`
  background-color: #000;
  height: 100%;
  position: relative;
  overflow: hidden;
  ${(props: { fullScreen?: boolean }) => props.fullScreen ? `
    iframe {
      cursor: pointer;
      box-sizing: border-box;
      height: 56.25vw;
      min-height: 100%;
      min-width: 100%;
      width: 177.77777778vh;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
    }` : `
    padding-bottom: 56.25%;
    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }`
  }
`

const BASE_URL = "https://player.vimeo.com/video"

interface VimeoPlayerOptions {
  autoplay?: number,
  loop?: number,
  muted?: number,
  playsinline?: number,
  background?: number,
}

interface VimeoPlayerProps {
  id?: string,
  title?: string,
  vimeoId: string,
  fullScreen?: boolean,
  autoplay?: boolean,
  loop?: boolean,
  muted?: boolean,
  playsinline?: boolean,
  background?: boolean,
  className?: string
}

const VimeoPlayer: FC<VimeoPlayerProps> = ({
  id,
  title,
  vimeoId,
  fullScreen,
  autoplay,
  loop,
  muted,
  playsinline,
  background,
  className
}) => {
  const cfg: VimeoPlayerOptions = {
    autoplay: autoplay ? 1 : 0,
    loop: loop ? 1 : 0,
    playsinline: playsinline ? 1 : 0,
    background: background ? 1 : 0,
}

  if(muted) cfg.muted = 1

  const src = `${BASE_URL}/${vimeoId}?${stringifyParams(cfg)}`

  return (
    <Container
      fullScreen={fullScreen}
      className={cn(className)}
    >
      <iframe
        id={id || ""}
        title={title || ""}
        src={src}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      />
    </Container>
  )
}

export default VimeoPlayer
