export const parseParams = <T>(s: string): T | null => {
  if(!s || s === "") return null

  const j = decodeURIComponent(s.substring(1))
    .replace(/"/g, '\\"')
    .replace(/&/g, '","')
    .replace(/=/g, '":"')

  return JSON.parse(`{"${j}"}`)
}

export const stringifyParams = (o: Object): string => {
  return Object.entries(o)
    .filter(([ key, value ]) => {
      if(value === false || value === 0) return true
      if(value && value.length === 0) return false
      return !!value
    })
    .map(([ key, value ]) => {
      const urlKey = encodeURIComponent(key)
      const urlValue = encodeURIComponent(value)
      return `${urlKey}=${urlValue}`
    })
    .join("&")
}
